import { Flex, Tag, ButtonLink, Text, SrOnly } from '@applyboard/crystal-ui';
import { ChevronRightOutlineIcon } from '@applyboard/ui-icons';
import {
  Application,
  ApplicationsPageProps,
  SortableFields,
  getApplicantName,
  useFilterField,
} from '../../../hooks';
import { usePageProps } from '../../../context';
import styled from '@emotion/styled';
import { Table } from '../../../components/uie-fix/Table';
import { ApplicationState } from 'applications-types-lib';
import ApplicationStateTag from '../../../components/Application/ApplicationStateTag/ApplicationStateTag';

interface ApplicationsTableProps {
  readonly applications: Application[]
  readonly isFetchingApplications: boolean
}

const columnMappings: Record<string, SortableFields> = {
  intakeTerm: 'programIntake.program.name',
  '-intakeTerm': '-programIntake.program.name',
  submittedAt: 'submittedAt',
  '-submittedAt': '-submittedAt',
  status: 'status',
  '-status': '-status',
};

export function ApplicationsTable({ applications, isFetchingApplications }: ApplicationsTableProps) {
  const sortFilter = useFilterField('sort');

  return (
    <section aria-label="applications list">
      <div role="alert" aria-live="assertive">
        {isFetchingApplications && (
          <SrOnly>Loading applications</SrOnly>
        )}
      </div>
      <Flex hideBelow="sm" direction="column">
        <Table
          onSort={(columns) => {
            if (columns.length === 0) {
              // Third click was returning an empty value. Crystal issue?
              if (sortFilter.value[0] === '-') {
                const [newColumn, newColumnValue] = Object.entries(columnMappings).find(([key, value]) => value === sortFilter.value.slice(1)) ?? []
                if (newColumn) {
                  return sortFilter.setValue(newColumnValue);
                }
              }
              return sortFilter.setValue(columnMappings['-submittedAt']);
            }
            const column = columns[0];

            sortFilter.setValue(columnMappings[column.desc ? `-${column.id}` : `${column.id}`]);
          }}
          columns={[
            {
              accessorKey: 'tags',
              header: () => <SrOnly>Update</SrOnly>,
              width: 'content',
              cell: (application: Application) => {
                const hasChanged = application.attributes?.hasOpenDocumentRequests;
                if (hasChanged) {
                  return (
                    <div
                      style={{
                        height: '10px',
                        width: '10px',
                        borderRadius: '100%',
                        backgroundColor: '#FF00CC',
                      }}
                      aria-label="DocumentTag"
                    ></div>
                  );
                }
                return null;
              },
            },
            {
              accessorKey: 'attributes.humanReadableId',
              header: 'App. ID',
            },
            {
              accessorKey: 'applicantName',
              header: 'Applicant Name',
              cell: ColumnApplicantName,
            },
            {
              accessorKey: 'nationality',
              header: 'Nationality',
              cell: ColumnNationality,
            },
            {
              enableSorting: true,
              accessorKey: 'intakeTerm',
              header: 'Program Intake',
              cell: ColumnProgramIntake,
            },
            {
              enableSorting: true,
              accessorKey: 'submittedAt',
              header: 'Date Submitted',
              cell: ColumnSubmittedAt,
            },
            {
              enableSorting: true,
              accessorKey: 'status',
              header: 'Status',
              cell: ColumnStatus,
            },
            {
              accessorKey: 'link',
              header: () =>
                <SrOnlyWrapper>
                  <SrOnly>Application Links</SrOnly>
                </SrOnlyWrapper>,
              cell: ColumnLink
            },
          ]}
          data={applications}
          loading={isFetchingApplications}
          overflow="scroll"
        />
      </Flex>
    </section>
  );
}

function ColumnApplicantName(application: Application) {
  return <Table.TextCell>{`${getApplicantName(application)}`}</Table.TextCell>
}

function ColumnNationality(application: Application) {
  const applicant = application.attributes?.personalInformation?.basicPersonalInformation;

  return (
    <Table.CountryCell
      value={applicant?.nationality || ''}
    />
  );
}

function ColumnProgramIntake(application: Application) {
  return (
    <Table.TextCell>
      <Flex direction="column" gap={1} p={2} align="start">
        {application.attributes?.programSelected?.program?.name}
        <Tag intent="secondary" size="sm">
          {application.attributes?.programSelected?.programIntakeTerm?.name}
        </Tag>
      </Flex>
    </Table.TextCell>
  )
}

function ColumnSubmittedAt(application: Application) {
  const submittedAt = application.attributes?.applicationInformation?.submittedAt
  if (!submittedAt) {
    return null
  }

  return (
    <Table.DateCell
      value={submittedAt}
      format="MMM dd, yyyy"
    />
  )
}

function ColumnStatus(application: Application) {
  const { referenceData } = usePageProps<ApplicationsPageProps>();
  const state = application.attributes?.applicationState as ApplicationState
  const tagProps = referenceData.applicationStatuses[state]

  return (
    <Table.Cell>
      <ApplicationStateTag state={state} hasOpenDocumentRequests={application.attributes?.hasOpenDocumentRequests} vertical={true} />
    </Table.Cell>
  )
}

function ColumnLink(application: Application) {
  return (
    <Table.Cell>
      <ButtonLink
        aria-label={`Application ${application.attributes?.humanReadableId}`}
        emphasis="transparent"
        href={`/applications/${application.id}`}
        intent="secondary"
        leadIcon={() => {
          return <ChevronRightOutlineIcon size="md" />;
        }}
        size="sm"
        width="hug"
      />
    </Table.Cell>
  )
}

const SrOnlyWrapper = styled.div({
  position: 'relative',
});
