import { Flex, Tag, TagProps } from '@applyboard/crystal-ui'
import { ApplicationState } from 'applications-types-lib'
import { ApplicationState as AppStatus } from '../../../hooks'

type ApplicationStateTagProps = {
  state?: ApplicationState,
  hasOpenDocumentRequests?: boolean,
  vertical?: boolean
}

export default function ApplicationStateTag(props: ApplicationStateTagProps) {
  const getStateInfo = () => {
    let intent = 'primary' as TagProps['intent']
    let label = 'Submitted'
    
    const shouldDisplayDocumentRequestedTag = 
      props.hasOpenDocumentRequests &&
      (
        props.state === ApplicationState.INITIAL_OFFER ||
        props.state === ApplicationState.FINAL_OFFER_ISSUED ||
        props.state === ApplicationState.WAITLISTED
      )
    
    if (props.hasOpenDocumentRequests && !shouldDisplayDocumentRequestedTag) {
      label = 'Document Requested'
      intent = 'warning'
    }

    switch (props.state) {
      case ApplicationState.DECLINED:
        intent = 'negative'
        label = 'Declined'
        break
      case ApplicationState.PAYMENT_PROCESSING:
        intent = 'magic' as TagProps['intent']
        label = 'Payment Processing'
        break
      case ApplicationState.INITIAL_OFFER:
        intent = 'positive'
        label = 'Pre-Offer'
        break
      case ApplicationState.FINAL_OFFER_ISSUED:
        intent = 'positive'
        label = 'Final Offer'
        break
      case ApplicationState.WAITLISTED:
        intent = 'warning'
        label = 'Waitlist'
        break
      case ApplicationState.DRAFT:
        intent = 'warning'
        label = 'Draft'
        break
      case ApplicationState.DEFERRAL_REQUESTED_IN_ASSESSMENT:
      case ApplicationState.DEFERRAL_REQUESTED_IN_SCREENING:
      case ApplicationState.DEFERRAL_REQUESTED_IN_INITIAL_OFFER:
        intent = 'warning'
        label = 'Deferral Requested'
        break
    }

    return {
      intent,
      label,
      shouldDisplayDocumentRequestedTag
    }
  }

  const { intent, label, shouldDisplayDocumentRequestedTag } = getStateInfo()

  return (
    <Flex gap={2} align={'start'} direction={props.vertical ? 'column' : 'row'}>
      {shouldDisplayDocumentRequestedTag && <Tag icon="fill" intent="warning">Document Requested</Tag>}
      <Tag icon="fill" intent={intent}>
        {label}
      </Tag>
    </Flex>
  )
}
